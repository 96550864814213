import { Campaign } from './user.model';

export interface AdminUser {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  company_id: number;
  period_id: number;
  is_user_admin: boolean;
  is_account_representative: boolean;
  profile_picture: string;
  is_staff: boolean;
  is_active: number;
  is_superuser: boolean;
  created_at: string;
  last_login: string;
  modified_at: string;
  campaign_ids: number[];
  campaign_details: Campaign[];
}

export interface AdminUserFilters {
  company_id: string;
  campaign_id: string;
  limit: string;
  page: string;
  search_value: string;
  sort_by: string;
  sort_order: string;
  total_pages?: string;
}

export interface NewAdminUser {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  is_account_representative: boolean;
}

export const ADMIN_WITH_NO_CAMPAIGNS_ROUTE = '/management/campaigns';
